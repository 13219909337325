(function ($, Drupal, site, ElcServiceBus, ServiceBusTopics, generic, ELCCookie) {
  /** From the iFrame, send the following postMessage via the iFrame Resizer API
   * {
   *    '_elc_action': 'addtobag|analytics|analyticsAction|redirect',
   *    '_elc_analytics_action_name': String, // this will be the action name passed to analytics
   *    ...restOfTheObject,
   * }
   *
   * The iFrame Content window can also specify the height of the iFrame:
   *
   *   window.iFrameResizer = {
   *     heightCalculationMethod: function() {
   *       return 700;
   *     }
   *   }
   */
  Drupal.behaviors.elcIFrameContentExperienceV1 = {
    attach: function (context, settings) {
      const self = this;
      // For JS Agg brands, the var's are not defined, so reloading them here.
      ServiceBusTopics = ServiceBusTopics || window.ServiceBusTopics;
      ElcServiceBus = ElcServiceBus || window.ElcServiceBus;

      const $iframeTemplates = $(
        '.js-elc-iframe-content-experience--v1 iframe:not(.attached)',
        context
      );
      $iframeTemplates.each(function () {
        const $this = $(this);
        let src = $this.data('src');
        let scroll = $this.data('scrolling');
        if ($this.data('dynamicSrc')) {
          src = self.generateLocaleSrc(src);
        }
        $this.attr('src', src);
        let scrollValue = scroll === 1 ? 'yes' : 'no';
        $this.addClass('attached').iFrameResize({
          checkOrigin: false,
          heightCalculationMethod: 'bodyScroll',
          scrolling: scrollValue,
          waitForLoad: true,
          warningTimeout: 0,
          onMessage: function (response) {
            if (!response) {
              return false;
            }
            const msg = response.message;
            const data = typeof msg === 'string' ? JSON.parse(msg) : msg;
            const action = data['_elc_action'];
            $this.trigger('iframe.message', [data]);
            delete data['_elc_action'];
            self.processPostMessageAction(self, $this, action, data);
          }
        });
      });
    },

    generateLocaleSrc: function (src) {
      const mpConfig = window.MP || {
        SrcLang: 'en'
      };
      const srcParts = src.split('/');
      let iframeId = srcParts.pop();
      let locale = $.cookie('LOCALE');
      locale =
        !!locale && locale.indexOf(mpConfig.SrcLang) === -1
          ? locale.toLowerCase().replace('_', '-')
          : '';
      if (!!locale) {
        src = srcParts.join('/').concat('/').concat(locale.concat('-').concat(iframeId));
      }
      return src;
    },

    getCartCookieObject: function () {
      const cookieArray = generic.cookie('FE_USER_CART')
        ? generic.cookie('FE_USER_CART').split('&')
        : [];
      const responseObject = {};
      cookieArray.forEach((item) => {
        const splitPair = item.split(':');
        const key = splitPair[0];
        const value = splitPair[1] || false;
        responseObject[key] = value;
      });
      return responseObject;
    },

    sendPostMessage: ($iframe, action, resp) => {
      $iframe.get(0).contentWindow.postMessage(
        JSON.stringify({
          ...{ _elc_action: action },
          ...resp
        }),
        '*'
      );
    },

    parseJsonRpcResponseObjectOrFunction: function (r) {
      try {
        if (typeof r.getValue === 'function') {
          return r.getValue();
        }
        return r.result.value;
      } catch (e) {
        console.log(e);
      }
    },

    // eslint-disable-next-line complexity
    processPostMessageAction: async (self, $iframe, action, data) => {
      switch (action) {
        case 'addtobag':
          $(document).trigger('elc.iframe.addToBag', [$iframe, data]);
          break;
        case 'api.cms':
          $.ajax({
            url: '/elc_api/node/' + data.nid,
            success: function (data) {
              self.sendPostMessage($iframe, action, {
                ...{ _meta: { success: true } },
                ...data
              });
            },
            error: function (err) {
              self.sendPostMessage($iframe, action, {
                ...{ _meta: { success: false } },
                ...err.getValue()
              });
            }
          });
          break;
        case 'api.jsonrpc':
          generic.jsonrpc.fetch({
            method: data.method,
            params: data.params,
            onSuccess: function (response) {
              self.sendPostMessage($iframe, action, {
                ...{ _meta: { success: true } },
                ...self.parseJsonRpcResponseObjectOrFunction(response)
              });
            },
            onFailure: function (err) {
              self.sendPostMessage($iframe, action, {
                ...{ _meta: { success: false } },
                ...err.getValue()
              });
            }
          });
          break;
        case 'quiz.getHairCareFinderResults':
        case 'quiz.save_hair_care_finder_results':
          const quizID = data.id || 'default';
          const cookieName = `iframe.quiz.${quizID}`;
          const parsedUserCookie = self.getCartCookieObject();
          const isSignedIn = parsedUserCookie.signed_in === '1';

          const guestGetFn = () => {
            self.sendPostMessage($iframe, action, {
              ...{ _meta: { authenticated: isSignedIn, success: true } },
              ...{ value: ELCCookie.getCookie(cookieName) }
            });
          };
          const guestSaveFn = (value) => {
            ELCCookie.setCookie(
              cookieName,
              JSON.stringify(value),
              $iframe.parent().data('expires')
            );
          };
          const userGetFn = () => {
            generic.jsonrpc.fetch({
              method: action,
              onSuccess: function (response) {
                self.sendPostMessage($iframe, action, {
                  ...{ _meta: { authenticated: isSignedIn, success: true } },
                  ...{ value: self.parseJsonRpcResponseObjectOrFunction(response) }
                });
              },
              onFailure: function (err) {
                self.sendPostMessage($iframe, action, {
                  ...{ _meta: { authenticated: isSignedIn, success: false } },
                  ...{ value: err.getValue() }
                });
              }
            });
          };
          const userSaveFn = (value) => {
            generic.jsonrpc.fetch({
              method: action,
              params: value,
              onSuccess: function (response) {
                self.sendPostMessage($iframe, action, {
                  ...{ _meta: { authenticated: isSignedIn, success: true } },
                  ...{ value: self.parseJsonRpcResponseObjectOrFunction(response) }
                });
              },
              onFailure: function (err) {
                self.sendPostMessage($iframe, action, {
                  ...{ _meta: { authenticated: isSignedIn, success: false } },
                  ...{ value: err.getValue() }
                });
              }
            });
          };
          if (!isSignedIn) {
            // Guest user
            if (action === 'quiz.save_hair_care_finder_results') {
              guestSaveFn(data.params);
            } else if (action === 'quiz.getHairCareFinderResults') {
              guestGetFn();
            }
          } else {
            // Logged in user
            if (action === 'quiz.save_hair_care_finder_results') {
              userSaveFn(data.params);
            } else if (action === 'quiz.getHairCareFinderResults') {
              userGetFn();
            }
          }
          break;
        case 'product.getProducts':
          let productServiceIsGraphQL =
            Drupal.settings &&
            Drupal.settings.product_service &&
            Drupal.settings.product_service === 'graphql';
          let isRequestingGraphQL =
            data && data.productService && data.productService === 'graphql';
          let isRequestingPerlgem =
            data && data.productService && data.productService === 'perlgem';
          let productServiceUsedIsGraphQL =
            isRequestingGraphQL || (productServiceIsGraphQL && !isRequestingPerlgem);
          let productData;
          let promises = {};
          try {
            if (productServiceUsedIsGraphQL) {
              if (data.productIds) {
                let queryOptions = {
                  filterBy: {
                    productIds: data.productIds
                  }
                };
                if (data.queryOptions) {
                  queryOptions = { ...data.queryOptions, ...queryOptions };
                }
                promises.products = ElcServiceBus.query(
                  ServiceBusTopics.queries.GET_PRODUCT_DATA,
                  queryOptions
                );
              }
              if (data.skuIds) {
                promises.skus = ElcServiceBus.query(ServiceBusTopics.queries.GET_SKUS, data.skuIds);
              }
              // Perlgem sites
              // Prod IDs can be numbers, skus must have 'SKU' prefixed
            } else if (generic && generic.jsonrpc && data.productIds) {
              promises.products = new Promise((resolve, reject) => {
                generic.jsonrpc.fetch({
                  method: 'prodcat.querykey',
                  params: [
                    {
                      products: data.productIds,
                      query_key: 'catalog-mpp'
                    }
                  ],
                  onSuccess: function (r) {
                    resolve(self.parseJsonRpcResponseObjectOrFunction(r));
                  },
                  onFailure: function (e) {
                    reject(e);
                  }
                });
              });
            }
            if ($iframe.data('loadPwrData')) {
              promises.reviews = new Promise((resolve, reject) => {
                var url = settings.power_reviews.snippet_url;
                var apiKey = settings.power_reviews.api_key;
                var merchantId = settings.power_reviews.merchant_id;
                var language = settings.power_reviews.locale;

                url = url.replace('MERCHANT_ID', merchantId)
                        .replace('PRODUCT_IDS', data.productIds.join(',').replaceAll('PROD', ''))
                        .replace('API_KEY', apiKey)
                        .replace('LOCALE_LANGUAGE', language);
                
                $.ajax(url)
                  .done(function (r) {
                    resolve(r);
                  })
                  .fail(function (e) {
                    reject(e);
                  });
              });
            }
            Promise.all([promises.products, promises.skus, promises.reviews])
              .then((data) => {
                if (data[2]?.results) {
                  if (productServiceUsedIsGraphQL) {
                    data[0].map(function(product, i) {
                      var reviewsData = data[2].results.filter(function(review) {
                        return parseInt(review.page_id) ===  parseInt(product.PRODUCT_ID) && review.rollup;
                      });
                      // Reset to 0 what ever comes from .NET
                      data[0][i].reviews.averageRating = 0;
                      data[0][i].reviews.numberOfReviews = 0;
                      if (reviewsData.length > 0) {
                        data[0][i].reviews.averageRating = reviewsData[0].rollup.average_rating || 0;
                        data[0][i].reviews.numberOfReviews = reviewsData[0].rollup.review_count || 0;
                      }
                    });
                  } else {
                    data[0].products.map(function(product, i) {
                      var reviewsData = data[2].results.filter(function(review) {
                          return parseInt(review.page_id) ===  parseInt(product.PROD_BASE_ID) && review.rollup;
                        });
                        // Reset to 0 what ever comes from .NET
                        data[0].products[i].AVERAGE_RATING = 0;
                        data[0].products[i].TOTAL_REVIEW_COUNT = 0;
                        if (reviewsData.length > 0) {
                          data[0].products[i].AVERAGE_RATING = reviewsData[0].rollup.average_rating || 0;
                          data[0].products[i].TOTAL_REVIEW_COUNT = reviewsData[0].rollup.review_count || 0;
                        }
                    });
                  }
                  
                }
                productData = {
                  _meta: {
                    graphql: productServiceUsedIsGraphQL,
                    language: document.documentElement.getAttribute('lang'),
                    location: {
                      host: location.host,
                      pathname: location.pathname
                    }
                  },
                  products: data[0],
                  skus: data[1]
                };
              })
              .catch((e) => {
                productData = e;
              })
              .finally(() => {
                self.sendPostMessage($iframe, action, productData);
              });
          } catch (e) {
            console.log(e);
          }
          break;
        case 'analytics.evtView':
          if (site.track && site.track.evtView) {
            site.track.evtView(data);
          }
          break;
        case 'analytics.evtAction':
          if (site.track && site.track.evtAction) {
            site.track.evtAction(data['_elc_analytics_action_name'], data);
          }
          break;
        case 'analytics.evtLink':
          if (site.track && site.track.evtLink) {
            site.track.evtLink(data);
          }
          break;
        case 'redirect':
          $(document).trigger('elc.iframe.redirectRequested', [$iframe, data]);
          break;
        case 'cookie.set':
          ELCCookie.setCookie(
            $iframe.parent().data('name'),
            JSON.stringify(data),
            $iframe.parent().data('expires')
          );
          break;
        case 'cookie.get':
          self.sendPostMessage($iframe, action, {
            value: ELCCookie.getCookie($iframe.parent().data('name'))
          });
          break;
        case 'cookie.delete':
          ELCCookie.eraseCookie($iframe.parent().data('name'));
          break;
        // Deprecated
        case 'analytics':
          if (site.track && site.track.evtLink) {
            site.track.evtLink(data);
          }
          break;
        // Deprecated
        case 'analyticsAction':
          if (site.track && site.track.evtAction) {
            site.track.evtAction(data['_elc_analytics_action_name'], data);
          }
          break;
      }
    }
  };
})(
  jQuery,
  Drupal,
  window.site || {},
  window.ElcServiceBus || false,
  window.ServiceBusTopics || false,
  generic || {},
  window.ELCCookie || {}
);
